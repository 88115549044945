const Projects = () => {
    return(
    <div>
        <h1>Personal</h1>
        <br/>
        <p><a href="https://github.com/franraknic/njuskalo-nekretnine" target="_blank" rel="noreferrer" >Njuškalo Nekretnine</a></p>
        <p>Web scraper for Njuškalo.hr - scraping realestate listings and persisting to a relational database. <br/>
            The plan was to implement a notification system for new listings. Development stopped due rigorous anti-scraping by Njuškalo.hr</p>
        <p>Built using:</p>
        <p>Python</p>
        <p><a href="https://scrapy.org/" target="_blank" rel="noreferrer" >Scrapy</a> - <a href="http://docs.peewee-orm.com/en/latest/" target="_blank" rel="noreferrer">peewee</a></p>
        <hr></hr>
        <br/>

        <p><a href="https://github.com/franraknic/SNCL" target="_blank" rel="noreferrer">SNCL</a></p>
        <p>Linguistic corpus maker using online forum discussions, analysing linguistic properties of a corpus and training a naive Bayes classifier to determine the subject of conversations.
            <br/> Part of the practical section of B.A.sc. thesis "Natural Language Processing using Python"
        </p>
        <p>Built using:</p>
        <p>Python</p>
        <p><a href="https://scrapy.org/" target="_blank" rel="noreferrer">Scrapy</a> - <a href="https://www.nltk.org/" target="_blank" rel="noreferrer">nltk</a></p>
        <hr></hr>
        <br/>

        <p><a href="https://github.com/franraknic/AssetManager3" target="_blank" rel="noreferrer">Asset3</a></p>
        <p>Asset management application that uses QR codes to manage assets of any kind. Prototype for scanning and inputing data and scanning and reading data.</p>
        <p>Built using:</p>
        <p>Java - PHP</p>
        <p><a href="https://square.github.io/retrofit/" target="_blank" rel="noreferrer">Retrofit</a> - <a href="https://github.com/google/gson" target="_blank" rel="noreferrer">Gson</a> - <a href="https://developers.google.com/vision" target="_blank" rel="noreferrer">Mobile Vision API</a></p>
        <br/>


        <h1>Commercial</h1>
        <br/>
        <p><a href="https://www.sedamit.hr/program-eu7/iri2-soc4-0" target="_blank" rel="noreferrer">IRI2 SOC4.0</a></p>
        <p>SedamIT d.o.o.</p>
        <p>Developing a network surveillance system that will enable a unified and real-time adaptable, threat-monitoring network infrastructure in the field of cybersecurity.</p>
        <p>Technology:</p>
        <p>Machine learning for classification of network activity</p>
        <p>Deep network packet inspection</p>
        <p>Automatic device discovery</p>
        <p>Distributed systems for data storage and message queueing</p>
        <hr></hr>
        <br/>

        <p><a href="https://gym-desk.com/en/home/" target="_blank" rel="noreferrer">GymDesk</a></p>
        <p>IDE3 d.o.o.</p>
        <p>Web application for running a sports center. Features include a member notification system, center resource management and planning.</p>
        <p>Technology:</p>
        <p>PHP - Laravel - MySQL - Websockets - Vue.js</p>
        <hr></hr>
        <br/>

        <p>Cybersharks</p>
        <p>Minting implementation using blockchain & NFT.</p>
        <p>Technology:</p>
        <p>Blockchain - Smart Contracts - React.js</p>
    </div>
    );
}

export default Projects;