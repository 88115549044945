import React from 'react';
import {useState} from "react";
import './App.css';
import About from './content/About';
import Cv from './content/Cv';
import Projects from './content/Projects';
import {BsMoon, BsSun} from 'react-icons/bs';



function App() {

  const [selectedItem, setSelectedItem] = useState('about');
  const [theme, setTheme] = useState('dark')
  //const [language, setLanguage] = useState('en')
  const dt = new Date();
  const yearNow = dt.getFullYear()

  const renderContent = (selectedItem) => {
    if(selectedItem === 'about'){
      return <About/>
    }
    if(selectedItem === 'cv'){
      return <Cv/>
    }
    if(selectedItem === 'projects'){
      return <Projects/>
    }
  }

  const renderThemeSwitch = () => {
    if(theme === 'light'){
      return(<div className={'switchButton hover-pointer'} onClick={() => themeClick()}><BsMoon /></div>)
    }

    if(theme === 'dark'){
      return(<div className={'switchButton hover-pointer'} onClick={() => themeClick()}><BsSun /></div>)
    }
  }

  /*
  const renderLanguageSwitch = () => {
    if(language == 'en'){
      return(<div className={'switchButton'} onClick={() => languageClick()}>en<MdSwitchLeft />hr</div>)
    }

    if(language == 'hr'){
      return(<div className={'switchButton'} onClick={() => languageClick()}>en<MdSwitchRight />hr</div>)
    }
  }

  const languageClick = () => {
    if(language == 'en'){
      setTheme('en')
    }else{
      setTheme('hr')
    }
  }
  */

  const themeClick = () => {
    if(theme === 'light'){
      setTheme('dark')
    }else{
      setTheme('light')
    }
  }

  return (
    <div className={"App " + theme}>
      <div className="row">
      <div className={'left-column'}>
          {renderContent(selectedItem)}
      </div>
      <div className={'right-column'}>
          {renderThemeSwitch()}
          <div className={'menu-item menu-item-first hover-pointer'} onClick={() =>{setSelectedItem('about')}}>{selectedItem === "about" ? "+ ": ""}About</div>
          <div className={'menu-item hover-pointer'} onClick={() =>{setSelectedItem('cv')}}>{selectedItem === "cv" ? "+ ": ""}CV</div>
          <div className={'menu-item hover-pointer'} onClick={() =>{setSelectedItem('projects')}}>{selectedItem === "projects" ? "+ ": ""}Projects</div>
          <footer className={"footer"}>&copy; Fran Raknić {yearNow}</footer>
      </div>
      </div>
    </div>
  );
}

export default App;
