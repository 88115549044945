import {FaGithub, FaLinkedin} from 'react-icons/fa';


const About = () => {

    const GitHubClick = () => {
        window.open('https://github.com/franraknic');
    }
    
    const LinkedInClick = () => {
        window.open('https://www.linkedin.com/in/fran-rakni%C4%87-bb7924122/');
    }

    return(
    <div>
        <h1>Fran Raknić</h1>
        <p>software developer @ <a href="https://www.sedamit.hr/" target="_blank" rel="noreferrer">SedamIT</a></p>
        <p>Zagreb, Croatia</p>
        <p>fran.raknic@gmail.com</p>
        <p><FaGithub onClick={() => GitHubClick()} size={40} className={'hover-pointer'} /> <FaLinkedin onClick={() => LinkedInClick()} size={40} className={'hover-pointer'} /></p>
        <br/><br/>
        <h3 className={'content-title'}>- skills -</h3>
        <p>software development</p>
        <p>devops implementation & maintainance</p>
        <p>IT infrastructure monitoring</p>
        <p>high throughput concurrent distributed systems</p>
        <p>information security</p>
        <br/>
        <h3>- tech -</h3>
        <p>Linux Docker NGINX</p>
        <p>Python Pandas Flask</p>
        <p>PHP Laravel</p>
        <p>Java Android</p>
        <p>Javascript React.js Vue.js</p>
        <p>Golang</p>
        <p>MySQL Postgres</p>
        <p>Elasticsearch Logstash Kibana</p>
        <p>Apache Kafka</p>
    </div>
    );
}

export default About;